import React from 'react';
import type { ReactNode } from 'react';

import type { I18nService } from 'common/src/types/i18n';

import { withMetrics } from '../../skyscanner-application/application-metrics';
import { withLogging } from '../LoggingContext';
import { withI18n } from '../../skyscanner-application/i18n';
import updatedErrorStack from '../../utils/update-error-stack';

import type { Metrics } from '../../skyscanner-application/types';
import type { Logger } from '../../types/logger';

type Props = {
  children: ReactNode;
  logger: Logger;
  metrics: Metrics;
  componentName: string;
  i18n: I18nService;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidCatch(
    error: any,
    { componentStack }: { componentStack: string },
  ) {
    this.setState({ hasError: true });

    const {
      componentName,
      i18n: { culture },
      logger,
      metrics,
    } = this.props;
    if (logger) {
      const { error: updatedError, stackData } = updatedErrorStack(
        error,
        componentStack,
      );
      logger.error(
        `Caught error in component ${componentName}`,
        stackData,
        updatedError,
      );
    }
    metrics.componentLoadFailure({ market: culture.market, componentName });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    return hasError ? null : children;
  }
}

export default withMetrics(withLogging(withI18n(ErrorBoundary)));
