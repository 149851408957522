import React, { Fragment } from 'react';

import { BpkContentBubble } from '@skyscanner/backpack-web/bpk-component-flare';
import BpkGraphicPromo, {
  TEXT_ALIGN,
} from '@skyscanner/backpack-web/bpk-component-graphic-promotion';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import PageTypes from 'common/src/pageTypes';
import type { UserContext } from 'common/src/types/user-context';
import type { Configs } from 'common/src/types/configs';
import type {
  HomePageProps,
  DomesticTravelProps,
} from 'common/src/types/home-page-props';
import type { I18nService } from 'common/src/types/i18n';

import CREATIVE_ASSET, {
  CREATIVE_ASSET_BLACK_FRIDAY_HOME_PAGE,
} from '../../services/creative-asset';
import { getDayviewUrl } from '../../services/url/url';
import SearchControls, { LAYOUT } from '../../components/SearchControls';
import BpkBreakpoint, {
  BREAKPOINTS,
} from '../../components/Common/BpkBreakpoint';
import { withI18n } from '../../skyscanner-application/i18n';
import { LAYOUT_TYPE } from '../../components/ViewedHotels/viewed-hotel-constant';
import ViewedHotels from '../../components/ViewedHotels';
import { withConfiguration } from '../../skyscanner-application/configuration';

import InspirationSection from './InspirationSection';
import Confidence from './Confidence';
import HotelUserValues from './HotelUserValues';
import DomesticTravel from './DomesticTravel';

import type {
  DealsAsset,
  PartialDealsAsset,
} from '../../services/creative-asset';
import type { SearchControlsOnSubmitParams } from '../../components/types';

import STYLES from './HomePage.scss';

export type Props = HomePageProps & {
  configs: Configs;
  userContext: UserContext;
  i18n: I18nService;
  domesticTravelProps: DomesticTravelProps | null;
};

const HomePage = ({
  confidenceProviders,
  configs,
  copy,
  domesticTravelProps,
  freeCancellation,
  heroImage,
  i18n: { culture, translate },
  inspirationCards,
  prefilledDestination,
  stay,
  userContext: { device, impressionId },
}: Props) => {
  const onSubmit = ({
    checkIn,
    checkOut,
    childrenAges,
    destination,
    filters = null,
    freeCancellation: freeCancellationFiled,
    numberOfAdults,
    numberOfRooms,
  }: SearchControlsOnSubmitParams) => {
    const stayParams = {
      checkIn,
      checkOut,
      numberOfAdults,
      numberOfRooms,
      childrenAges,
    };

    const TempFilters = filters;
    let freeCancellationQuery = freeCancellationFiled;
    if (filters && filters.cancellation) {
      freeCancellationQuery = true;
    }

    const win: Window = window;
    win.location.href = getDayviewUrl({
      destination,
      stay: stayParams,
      freeCancellation: freeCancellationQuery,
      filters: TempFilters,
      impressionId,
    });
  };

  const destinationLabel = () => {
    if (copy && copy.subheading) {
      return copy.subheading;
    }
    return translate('SearchControls_label_whereDoYouWantToStay');
  };

  const content = (
    <div className={STYLES.HomePage__searchSectionContent}>
      <div className={STYLES.HomePage__searchHeader}>
        <BpkText textStyle={TEXT_STYLES.hero4} tagName="h1">
          {copy.heading}
        </BpkText>
      </div>

      <div className={STYLES.HomePage__searchWrapper}>
        <BpkBreakpoint query={BREAKPOINTS.MOBILE}>
          {(isActive: boolean) => (
            <SearchControls
              destinationLabel={isActive ? '' : destinationLabel()}
              // @ts-expect-error TS2322: Type 'PrefilledDestination | null' is not assignable to type 'DestinationShape | undefined'.
              prefilledDestination={prefilledDestination}
              stay={stay}
              onSubmit={onSubmit}
              freeCancellation={freeCancellation}
              isMobile={isActive}
              showPreference={!isActive}
              layout={isActive ? LAYOUT.HORIZONTAL : LAYOUT.EXPANDABLE}
            />
          )}
        </BpkBreakpoint>
      </div>
    </div>
  );

  const {
    dealsPageEntryEnabled,
    dealsPageInMarketsDisabled,
    enableBlackFridayHotelDeals,
    viewedHotelsDesktopEnable,
  } = configs;
  const isFromBot = device && device.isRobot === true;
  const { locale, market } = culture;
  const localeReflection =
    market === 'UK' && locale === 'en-GB' ? 'en-UK' : locale;
  let localeCode: DealsAsset | PartialDealsAsset =
    CREATIVE_ASSET[localeReflection] || CREATIVE_ASSET.UNSET;
  if (enableBlackFridayHotelDeals) {
    localeCode =
      CREATIVE_ASSET_BLACK_FRIDAY_HOME_PAGE[locale] ||
      CREATIVE_ASSET_BLACK_FRIDAY_HOME_PAGE.UNSET;
  }
  return (
    <Fragment>
      <BpkContentBubble
        content={content}
        showPointer
        rounded={false}
        className={STYLES.HomePage__searchSection}
        style={{ backgroundImage: `url(${heroImage}` }}
      />
      {!dealsPageInMarketsDisabled && dealsPageEntryEnabled && (
        <div className={STYLES.HomePage__imgContainer}>
          <BpkBreakpoint query={BREAKPOINTS.MOBILE}>
            {(isActive: boolean) => (
              <BpkGraphicPromo
                headline={
                  enableBlackFridayHotelDeals
                    ? translate('HomePage_save_our_hottest_hotel_deals')
                    : translate('DealsPage_save_on_your_next_stay')
                }
                subheading={
                  enableBlackFridayHotelDeals
                    ? translate('HomePage_ends_30_november')
                    : translate(
                        isActive
                          ? 'DealsPage_Our_top_hotel_deals'
                          : 'DealsPage_we_pulled_top_offers_so_find_room_price',
                      )
                }
                buttonText={
                  enableBlackFridayHotelDeals
                    ? translate('HomePage_deals_banner_show_me')
                    : translate('DealsPage_Discover_a_deal')
                }
                className={
                  enableBlackFridayHotelDeals
                    ? STYLES.HomePage__graphicPromoFriday
                    : STYLES.HomePage__graphicPromo
                }
                style={{
                  backgroundImage: `url(${
                    isActive ? localeCode.homeMweb : localeCode.homeDesktop
                  })`,
                }}
                textAlign={TEXT_ALIGN.start}
                onClick={() => {
                  window.open(`/hotels/deals?source=${PageTypes.HOME}`);
                }}
              />
            )}
          </BpkBreakpoint>
        </div>
      )}

      {!isFromBot && viewedHotelsDesktopEnable ? (
        <ViewedHotels
          stay={stay}
          layoutType={LAYOUT_TYPE.SLIDER}
          viewedHotelsDesktopEnable={viewedHotelsDesktopEnable}
        />
      ) : null}

      {!prefilledDestination && domesticTravelProps && (
        <DomesticTravel {...domesticTravelProps} />
      )}

      {confidenceProviders && confidenceProviders.length > 0 && (
        <Confidence logoNames={confidenceProviders} />
      )}

      <div className={STYLES.HomePage__hotelUserValuesSection}>
        <HotelUserValues />
      </div>

      {inspirationCards && inspirationCards.length > 0 && (
        <InspirationSection cards={inspirationCards} />
      )}
    </Fragment>
  );
};

export default withConfiguration(withI18n(HomePage));
