import React from 'react';

import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';
import BpkCard from '@skyscanner/backpack-web/bpk-component-card';
import BpkSmallChevronRight from '@skyscanner/backpack-web/bpk-component-icon/sm/chevron-right';
import { withAlignment } from '@skyscanner/backpack-web/bpk-component-icon';
import {
  lineHeightBase,
  iconSizeSm,
} from '@skyscanner/bpk-foundations-web/tokens/base.es6';

import type { InspirationCard as InspirationCardType } from 'common/src/types/home-page-props';

import { LazyLoadedImage } from '../../../components/Common/Image';

import STYLES from './InspirationCard.scss';

type Props = {
  card: InspirationCardType;
};

const AlignChevronRight = withAlignment(
  BpkSmallChevronRight,
  lineHeightBase,
  iconSizeSm,
);

const InspirationCard = ({ card }: Props) => {
  const { imageUrl, link, name, nation } = card;

  return (
    <BpkCard
      className={STYLES.InspirationCard}
      padded={false}
      href={link}
      data-test-id="inspiration-card"
    >
      <LazyLoadedImage
        altText=""
        className={STYLES.InspirationCard__image}
        src={imageUrl}
        aspectRatio={2}
      />
      <div className={STYLES.InspirationCard__content}>
        <BpkText
          textStyle={TEXT_STYLES.heading3}
          tagName="p"
          className={STYLES.InspirationCard__name}
        >
          {name}
        </BpkText>
        <div className={STYLES.InspirationCard__nation}>
          <BpkText
            tagName="p"
            textStyle={TEXT_STYLES.label1}
            className={STYLES['InspirationCard__nation--name']}
          >
            {nation}
            <AlignChevronRight
              className={STYLES.InspirationCard__rtlTransform}
            />
          </BpkText>
        </div>
      </div>
    </BpkCard>
  );
};

export default InspirationCard;
