import React from 'react';

import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import type { ConfidenceProviders } from 'common/src/types/utils';

import { LazyLoadedImage } from '../../../components/Common/Image';
import { FormattedMessage } from '../../../skyscanner-application/i18n';
import logos from '../../../components/logos';

import STYLES from './Confidence.scss';

type Props = {
  logoNames: ConfidenceProviders;
};
const Confidence = ({ logoNames }: Props) => (
  <div className={STYLES.Confidence__confidenceSection}>
    <div className={STYLES.Confidence}>
      <BpkText
        tagName="h2"
        textStyle={TEXT_STYLES.label1}
        className={STYLES.Confidence__text}
      >
        <FormattedMessage label="Confidence_label_getBestPrices" />
      </BpkText>

      <div className={STYLES.Confidence__logos}>
        {logoNames.map((name) => (
          <div key={name} className={STYLES.Confidence__providerLogo}>
            <LazyLoadedImage
              altText={name}
              className={STYLES.Confidence__providerLogoImage}
              src={logos[name]}
              aspectRatio={220 / 80}
            />
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default Confidence;
