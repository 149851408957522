import React from 'react';

import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import type { I18nService } from 'common/src/types/i18n';

import { LazyLoadedImage } from '../../../components/Common/Image';
import { withI18n } from '../../../skyscanner-application/i18n';

import SeeItAll from './SeeItAll.svg';
import CompareRightHere from './CompareRightHere.svg';
import GetExclusiveRates from './GetExclusiveRates.svg';

import STYLES from './HotelUserValues.scss';

type ValueProps = {
  title: string;
  text: string;
  image: string;
  className?: string;
};

const Value = ({ className, image, text, title }: ValueProps) => (
  <div className={[STYLES.HotelUserValues__value, className].join(' ')}>
    <div className={STYLES.HotelUserValues__message}>
      <LazyLoadedImage
        altText=""
        className={STYLES.HotelUserValues__image}
        src={image}
        aspectRatio={1}
      />
      <div className={STYLES.HotelUserValues__wrapper}>
        <BpkText textStyle={TEXT_STYLES.heading3} tagName="h2">
          {title}
        </BpkText>
        <BpkText tagName="p" className={STYLES.HotelUserValues__messageText}>
          {text}
        </BpkText>
      </div>
    </div>
  </div>
);

Value.defaultProps = {
  className: null,
};

type HotelUserValuesProps = {
  i18n: I18nService;
};

const HotelUserValues = ({ i18n: { translate } }: HotelUserValuesProps) => (
  <div className={STYLES.HotelUserValues}>
    <Value
      title={translate('HotelUserValues_label_seeItAllTitle')}
      text={translate('HotelUserValues_label_seeItAllText')}
      className={STYLES.HotelUserValues__searchValue}
      image={SeeItAll}
    />
    <Value
      title={translate('HotelUserValues_label_compareTitle')}
      text={translate('HotelUserValues_label_compareText')}
      className={STYLES.HotelUserValues__compareValue}
      image={CompareRightHere}
    />
    <Value
      title={translate('HotelUserValues_label_getRatesTitle')}
      text={translate('HotelUserValues_label_getRatesText')}
      className={STYLES.HotelUserValues__saveValue}
      image={GetExclusiveRates}
    />
  </div>
);

export default withI18n(HotelUserValues);
