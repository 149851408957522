import React from 'react';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import type { InspirationCards } from 'common/src/types/home-page-props';

import { FormattedMessage } from '../../../skyscanner-application/i18n';

import InspirationCard from './InspirationCard';

import STYLES from './InspirationSection.scss';

const cls = cssModules(STYLES);

type Props = {
  cards: InspirationCards;
};

const InspirationSection = ({ cards }: Props) => (
  <div className={cls('InspirationSection')}>
    <div className={cls('InspirationSection__title')}>
      <BpkText textStyle={TEXT_STYLES.heading2} tagName="h2">
        <FormattedMessage label="SearchPage_label_needInspiration" />
      </BpkText>
      <div className={cls('InspirationSection__subtitle')}>
        <BpkText tagName="p">
          <FormattedMessage label="SearchPage_label_viewHandPicked" />
        </BpkText>
      </div>
    </div>

    <div className={cls('InspirationSection__grid')}>
      {cards.map((card) => (
        <InspirationCard key={card.link} card={card} />
      ))}
    </div>
  </div>
);

export default InspirationSection;
