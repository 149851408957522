import React from 'react';

import HotelRecommendations from '@skyscanner/hotel-recommendations';

import type { DomesticTravelProps } from 'common/src/types/home-page-props';

import ErrorBoundary from '../../../components/Common/ErrorBoundary';

import STYLES from './DomesticTravel.scss';

const DomesticTravel = (props: DomesticTravelProps) => (
  <ErrorBoundary componentName="DomesticTravel">
    <div className={STYLES.DomesticTravel}>
      <HotelRecommendations {...props} />
    </div>
  </ErrorBoundary>
);

export default DomesticTravel;
